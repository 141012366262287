import React from 'react'
import { TransitionGroup, Transition as ReactTransition } from 'react-transition-group'
import PropTypes from 'prop-types'
// import * as styles from './style.module.sass'

const timeout = 0
// const appearTimeout = 200
const getTransitionStyles = {
    entering: {
        zIndex: -1,
        opacity: 0,
        // position: 'absolute', // projectListのgif imgを消す部分がずれる?
        // top: 0,
        // left: 0,
        // width: 0,
        // height: 0,
        // overflow: 'hidden',
    },
    entered: {
        opacity: 1,
        // transition: `opacity ${timeout}ms cubic-bezier(0.000, 1.100, 0.000, 0.980)`,
    },
    exiting: {
        opacity: 0,
        // transition: `opacity ${timeout}ms cubic-bezier(0.000, 1.100, 0.000, 0.980)`,
    },
    exited: {
        opacity: 0,
        display: 'none',
    },
}

const Transition = ({ children, location }) => {
    return (
        <TransitionGroup>
            <ReactTransition
                key={location.pathname}
                timeout={{
                    // appear: appearTimeout,
                    enter: timeout,
                    exit: timeout,
                }}
                animate
                // appear
            >
                {(status) => (
                    <div
                        style={{
                            ...getTransitionStyles[status],
                        }}
                    >
                        {children}
                    </div>
                )}
            </ReactTransition>
        </TransitionGroup>
    )
}

Transition.prototype = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
}

export default Transition
