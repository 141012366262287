import React from 'react'
import { useRecoilState } from 'recoil'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { useLocation } from '@reach/router'
import { menuOpenState } from '../../../hooks/atoms/menuOpen'
import { isWindows } from '../../../hooks/atoms/isWindows'
import * as styles from './style.module.sass'

import Hover from '../../utils/hover'

import DownloadIcon from '../../../images/download-icon.svg'
import DownloadIconSP from '../../../images/download-icon_sp.svg'
import FooterLayoutModel from '../../model/footerLayoutModel'

const Navigation = ({ _pdfLink, _time, _isInMenu }) => {
    const location = useLocation()
    const [isMenuOpen, setMenuState] = useRecoilState(menuOpenState)
    const [isWindowsBool] = useRecoilState(isWindows)

    const navigationList = [
        { title: 'Works', to: '/', id: 'works' },
        { title: 'About', to: '/about/', id: 'about' },
        { title: 'Media', to: '/media/', id: 'media' },
        { title: 'Contact', to: '/contact/', id: 'contact' },
    ]

    const getMenuClass = () => {
        let className = ''
        if (_isInMenu && isMenuOpen)
            className += ` ${styles.navigation_is_in_menu} ${styles.navigation_is_open}`
        else if (_isInMenu) className += ` ${styles.navigation_is_in_menu}`
        if (isWindowsBool) className += ` ${styles.navigation_is_in_menu_win}`
        return className
    }

    const closeMenu = () => {
        if (!_isInMenu) return
        setMenuState(false)
        if (typeof window === 'undefined') return
        // htmlをスクロール不可に変更
        const htmlClass = document.documentElement.classList
        htmlClass.remove('is-fixed')
    }

    return (
        <nav className={getMenuClass()}>
            <div className={styles.navigation_is_in_menu_inner}>
                <ol className={styles.navigation__list}>
                    <li className={styles.navigation__list_item}>
                        <ol className={styles.navigation__main_list}>
                            {navigationList.map((navigationItem) => {
                                return (
                                    <li
                                        key={navigationItem.id}
                                        className={styles.navigation__main_item}
                                    >
                                        <Hover
                                            hoverClass={styles.navigation__main_item__link_hovered}
                                        >
                                            {location.pathname === navigationItem.to ? (
                                                <button
                                                    type="button"
                                                    onClick={() => closeMenu()}
                                                    className={`${styles.navigation__main_item__link} ${styles.navigation__main_item__link_active}`}
                                                >
                                                    {navigationItem.title}
                                                </button>
                                            ) : (
                                                <Link
                                                    to={navigationItem.to}
                                                    onClick={() => closeMenu()}
                                                    className={styles.navigation__main_item__link}
                                                >
                                                    {navigationItem.title}
                                                </Link>
                                            )}
                                        </Hover>
                                    </li>
                                )
                            })}
                        </ol>
                    </li>
                    {_pdfLink && (
                        <li className={styles.navigation__list_item}>
                            <div className={styles.navigation__pdf_wrap}>
                                <Hover hoverClass={styles.navigation__pdf_hovered}>
                                    <a
                                        href={_pdfLink}
                                        className={styles.navigation__pdf}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <span className={styles.navigation__pdf_icon}>
                                            <picture>
                                                <source
                                                    srcSet={DownloadIconSP}
                                                    media="(max-width: 900px)"
                                                />
                                                <img src={DownloadIcon} alt="download icon" />
                                            </picture>
                                        </span>
                                        <span className={styles.navigation__pdf_text}>PDF</span>
                                    </a>
                                </Hover>
                            </div>
                        </li>
                    )}
                </ol>
                {_isInMenu && <FooterLayoutModel _time={_time} />}
            </div>
        </nav>
    )
}

Navigation.defaultProps = {
    _pdfLink: null,
    _time: 0,
    _isInMenu: false,
}

Navigation.propTypes = {
    _pdfLink: PropTypes.string,
    _time: PropTypes.number,
    _isInMenu: PropTypes.bool,
}

export default Navigation
