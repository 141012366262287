import React from 'react'
import PropTypes from 'prop-types'
import * as styles from './style.module.sass'

const FooterLayoutModel = ({ _time }) => {
    const getHour = () => {
        const number = parseInt((_time / 3600).toString(), 10)
        const digits = number.toString().length <= 2 ? -2 : -1 * number.toString().length
        return `00${number}`.slice(digits)
    }

    const getMinute = () => {
        const number = parseInt(((_time / 60) % 60).toString(), 10)
        return `00${number}`.slice(-2)
    }

    const getSecond = () => {
        const number = _time % 60
        return `00${number}`.slice(-2)
    }

    return (
        <div className={styles.footer}>
            <p className={styles.footer__counter}>
                <span className={styles.footer__counter_num}>{getHour()}</span>
                <span className={styles.footer__counter_num}>{getMinute()}</span>
                <span className={styles.footer__counter_num}>{getSecond()}</span>
            </p>
            <p className={styles.footer__copyright}>
                <small className={styles.footer__copyright_body}>&copy; Kota Iguchi</small>
            </p>
        </div>
    )
}

FooterLayoutModel.propTypes = {
    _time: PropTypes.number.isRequired,
}

export default FooterLayoutModel
