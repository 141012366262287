export const isSmartphone = () => {
    return (
        navigator.userAgent.indexOf('Android') > 0 ||
        navigator.userAgent.indexOf('iPhone') > 0 ||
        navigator.userAgent.indexOf('iPod') > 0 ||
        (navigator.userAgent.indexOf('Android') > 0 && navigator.userAgent.indexOf('Mobile') > 0)
    )
}

export const browser = () => {
    const { userAgent } = window.navigator

    if (userAgent.indexOf('msie') !== -1 || userAgent.indexOf('trident') !== -1) {
        return 'ie'
    }
    if (userAgent.indexOf('edge') !== -1) {
        return 'edge'
    }
    if (userAgent.indexOf('Safari') !== -1 && userAgent.indexOf('CriOS') !== -1) {
        // -iOSのみ有効
        return 'chrome'
    }
    if (userAgent.indexOf('Safari') !== -1) {
        return 'safari'
    }
    if (userAgent.indexOf('firefox') !== -1) {
        return 'firefox'
    }
    if (userAgent.indexOf('opera') !== -1) {
        return 'opera'
    }
    return 'other'
}
