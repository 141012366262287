// import methods
import { init as InitViewPort, resize as ResizeViewPort } from './_viewport'

const PrependsScripts = (useRef, useEffect) => {
    const ticking = useRef(false)
    const viewWidth = useRef(null)
    const isMobile = useRef(
        typeof window === 'undefined'
            ? false
            : !!(
                  /android|ipod|ipad|iphone|macintosh/.test(navigator.userAgent.toLowerCase()) &&
                  'ontouchend' in document
              )
    )

    /// ////////////////////////////////////////////////////
    // INITIAL SCRIPTS
    /// ////////////////////////////////////////////////////
    useEffect(() => {
        if (typeof window === 'undefined') return
        InitViewPort()
        viewWidth.current = window.innerWidth
    }, [])

    /// ////////////////////////////////////////////////////
    // DOM LOADED
    /// ////////////////////////////////////////////////////

    /// ////////////////////////////////////////////////////
    // RESIZE
    /// ////////////////////////////////////////////////////
    useEffect(() => {
        if (typeof window === 'undefined') return null
        window.addEventListener('resize', resizeEventTicking)
        return () => window.removeEventListener('resize', resizeEventTicking)
    })

    const resizeEventTicking = () => {
        if (!ticking.current) {
            window.requestAnimationFrame(() => {
                ResizeViewPort(isMobile.current, viewWidth.current !== window.innerWidth)
                ticking.current = false
                viewWidth.current = window.innerWidth
            })
            ticking.current = true
        }
    }

    /// ////////////////////////////////////////////////////
    // SCROLL
    /// ////////////////////////////////////////////////////
}

export default PrependsScripts
