// extracted by mini-css-extract-plugin
export var bowAnimation = "style-module--bowAnimation--6754c";
export var navigation__list = "style-module--navigation__list--ffa9b";
export var navigation__list_item = "style-module--navigation__list_item--355b3";
export var navigation__logo = "style-module--navigation__logo--281ff";
export var navigation__main_item = "style-module--navigation__main_item--370e7";
export var navigation__main_item__link = "style-module--navigation__main_item__link--42113";
export var navigation__main_item__link_active = "style-module--navigation__main_item__link_active--2d3ba";
export var navigation__main_item__link_hovered = "style-module--navigation__main_item__link_hovered--24b29";
export var navigation__main_list = "style-module--navigation__main_list--44ef5";
export var navigation__pdf = "style-module--navigation__pdf--c2313";
export var navigation__pdf_hovered = "style-module--navigation__pdf_hovered--66c20";
export var navigation__pdf_icon = "style-module--navigation__pdf_icon--bd9df";
export var navigation__pdf_text = "style-module--navigation__pdf_text--c6618";
export var navigation__pdf_wrap = "style-module--navigation__pdf_wrap--62ad5";
export var navigation_is_in_menu = "style-module--navigation_is_in_menu--cf412";
export var navigation_is_in_menu_inner = "style-module--navigation_is_in_menu_inner--0e96d";
export var navigation_is_in_menu_win = "style-module--navigation_is_in_menu_win--a121d";
export var navigation_is_open = "style-module--navigation_is_open--7e04a";