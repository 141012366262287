let ua

const setUpView = () => {
    const html = document.getElementsByTagName('html')[0]
    const vh = window.innerHeight * 0.01
    const vw = ua.indexOf('windows nt') !== -1 ? window.innerWidth * 0.01 : html.clientWidth * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
    document.documentElement.style.setProperty('--vw', `${vw}px`)
}

// spの時に読み込んだ時のみリサイズして欲しいものを指定する
const setupSpVh = () => {
    const vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--spvh', `${vh}px`)
}

export const resize = (_isMobile: boolean, _isResizeWidth: boolean) => {
    setUpView()
    if (!_isMobile || (_isMobile && _isResizeWidth)) setupSpVh()
}

export const init = () => {
    if (typeof window === 'undefined') return
    ua = window.navigator.userAgent.toLowerCase()
    setUpView()
    setupSpVh()
}
