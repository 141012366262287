import React from 'react'
import * as styles from './style.module.sass'

const NotFoundText = () => {
    return (
        <section className={styles.not_found_text}>
            <h2 className={styles.not_found_text__title}>404</h2>
            <p className={styles.not_found_text__body}>Not found...</p>
        </section>
    )
}

export default NotFoundText
