import React from 'react'
import { useRecoilState } from 'recoil'
import * as styles from './style.module.sass'
import Hover from '../../utils/hover'

import { menuOpenState } from '../../../hooks/atoms/menuOpen'

const MenuButton = () => {
    const [isMenuOpen, setMenuState] = useRecoilState(menuOpenState)

    const openMenu = () => {
        setMenuState(!isMenuOpen)
        if (typeof window === 'undefined') return
        // htmlをスクロール不可に変更
        const htmlClass = document.documentElement.classList
        if (isMenuOpen) {
            htmlClass.remove('is-fixed')
        } else {
            htmlClass.add('is-fixed')
        }
    }

    return (
        <Hover hoverClass={styles.menu_button_hovered}>
            <button onClick={() => openMenu()} type="button" className={styles.menu_button}>
                {isMenuOpen ? 'Close' : 'Menu'}
            </button>
        </Hover>
    )
}

export default MenuButton
