// - refs: https://blog.nabeliwo.com/2018/05/react-hover/

import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
// import * as styles from './style.module.sass'

const Hover = ({ children, defaultStyle, hoverClass, hoverStyle, hoverMethods, isDesktopOnly }) => {
    const [isHover, setHover] = useState(false)
    const isMobile = useRef(
        typeof window === 'undefined'
            ? false
            : !!(
                  /android|ipod|ipad|iphone|macintosh/.test(navigator.userAgent.toLowerCase()) &&
                  'ontouchend' in document
              )
    )

    const addHover = () => {
        hoverMethods(true)
        setHover(true)
    }

    const removeHover = () => {
        hoverMethods(false)
        setHover(false)
    }

    return (
        <children.type
            {...children.props}
            className={`${children.props.className} ${
                isHover && hoverClass ? `${hoverClass}` : ''
            }`}
            style={isHover ? hoverStyle : defaultStyle}
            onMouseOver={() => {
                if (!isMobile.current && !isHover) addHover()
            }}
            onMouseEnter={() => {
                if (!isMobile.current && !isHover) addHover()
            }}
            onMouseLeave={() => {
                if (!isMobile.current && isHover) removeHover()
            }}
            onTouchStart={() => {
                if (isMobile.current && !isHover && !isDesktopOnly) addHover()
            }}
            onTouchEnd={() => {
                if (isMobile.current && isHover && !isDesktopOnly) removeHover()
            }}
            onTouchCancel={() => {
                if (isMobile.current && isHover && !isDesktopOnly) removeHover()
            }}
        >
            {children.props.children}
        </children.type>
    )
}

Hover.defaultProps = {
    hoverClass: '',
    defaultStyle: {},
    hoverStyle: {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    hoverMethods: () => {},
    isDesktopOnly: false,
}

Hover.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    defaultStyle: PropTypes.oneOfType([PropTypes.object]),
    hoverClass: PropTypes.string,
    hoverStyle: PropTypes.oneOfType([PropTypes.object]),
    hoverMethods: PropTypes.func,
    isDesktopOnly: PropTypes.bool,
}

export default Hover
