import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import * as styles from './style.module.sass'

import Hover from '../../utils/hover'
import KanjiGuchi from '../../../images/logo-kanji-guchi.inline.svg'
import KanjI from '../../../images/logo-kanji-i.inline.svg'

const Logo = ({ _logoTextList }) => {
    const [typeText, setTypeText] = useState('')
    const typeTimeOut = useRef(null)
    const ueTypeTimeOut = useRef(null)
    const ueTypeTimer = useRef(null)
    let currentTypeNum = 0

    const getClass = () => {
        const className = `${styles.logo}  ${styles.logo_is_top}`
        return className
    }

    const getMaxArray = () => {
        let maxLength = 0
        let returnText = ''
        if (_logoTextList.length === 0) return ''
        for (let i = 0; i < _logoTextList.length; i += 1) {
            const targetNum = _logoTextList[i].logoText.length
            if (targetNum > maxLength) {
                maxLength = targetNum
                returnText = `_${_logoTextList[i].logoText}`
            }
        }
        return returnText
    }

    const logoDom = (_isDummy = false) => {
        const dom = (
            <>
                <span className={styles.logo__icon}>
                    <KanjI />
                </span>
                <span className={styles.logo__text}>
                    <span>kotaiguchi</span>
                    {(() => {
                        if (_logoTextList.length === 0) return ''
                        if (!_isDummy) {
                            return typeText
                        }
                        if (_isDummy) {
                            return getMaxArray()
                        }
                        return ''
                    })()}
                </span>
                {_logoTextList.length !== 0 && (
                    <span className={styles.logo__cursor}>
                        <KanjiGuchi />
                    </span>
                )}
            </>
        )
        return dom
    }

    const getRandomInt = (_min, _max) => {
        return Math.floor(Math.random() * (_max - _min)) + _min
    }

    const hoverFunc = (_isHover) => {
        if (_isHover) resetType()
        else startType()
    }

    const resetType = () => {
        clearInterval(ueTypeTimer.current)
        clearTimeout(ueTypeTimeOut.current)
        clearTimeout(typeTimeOut.current)
    }

    const startType = () => {
        setTypeText(() => '')
        currentTypeNum = getRandomInt(0, _logoTextList.length)
        ueTypeTimer.current = setInterval(() => {
            ueTypeTimeOut.current = setTimeout(() => {
                typeLogo()
            }, 100)
        }, 8000)
    }

    const typeLogo = () => {
        if (_logoTextList.length === 0) return
        const targetString = `_${_logoTextList[currentTypeNum].logoText}`
        const splitString = targetString.split('')
        let setChar = ''
        let typedIndex = 0
        typeTimeOut.current = (_delay) =>
            setTimeout(() => {
                if (typedIndex > splitString.length - 1) {
                    let tempNextVal = getRandomInt(0, _logoTextList.length)
                    while (currentTypeNum === tempNextVal) {
                        tempNextVal = getRandomInt(0, _logoTextList.length)
                    }
                    currentTypeNum = tempNextVal
                } else {
                    setChar += splitString[typedIndex]
                    setTypeText(() => setChar)
                    typedIndex += 1
                    typeTimeOut.current(getRandomInt(10, 120))
                }
            }, _delay)
        typeTimeOut.current(getRandomInt(10, 120))
    }

    useEffect(() => {
        startType()
        return () => resetType()
    }, [])

    return (
        <Hover hoverClass={styles.logo_hovered} hoverMethods={(_isHover) => hoverFunc(_isHover)}>
            <Link className={getClass()} to="/">
                <span className={styles.logo__dummy}>{logoDom(true)}</span>
                <span className={styles.logo__front}>{logoDom(false)}</span>
            </Link>
        </Hover>
    )
}

Logo.defaultProps = {
    _logoTextList: [],
}

Logo.propTypes = {
    _logoTextList: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
}

export default Logo
