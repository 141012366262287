// extracted by mini-css-extract-plugin
export var blinkCursor = "style-module--blinkCursor--25fe0";
export var logo = "style-module--logo--667f7";
export var logo__cursor = "style-module--logo__cursor--7ee66";
export var logo__dummy = "style-module--logo__dummy--6b13f";
export var logo__front = "style-module--logo__front--18750";
export var logo__icon = "style-module--logo__icon--89be0";
export var logo__text = "style-module--logo__text--3d5e7";
export var logo__type_motion = "style-module--logo__type_motion--7c8e6";
export var logo_hovered = "style-module--logo_hovered--707bf";
export var logo_is_not_top = "style-module--logo_is_not_top--65a39";
export var logo_is_top = "style-module--logo_is_top--48e96";
export var typeShow = "style-module--typeShow--290be";