import { atom } from 'recoil'

const getDeviceInfo = () => {
    if (typeof window === 'undefined') return false
    const ua = window.navigator.userAgent.toLowerCase()
    if (ua.indexOf('windows nt') !== -1) return true
    return false
}

export const isWindows = atom<boolean>({
    key: 'isWindows',
    default: getDeviceInfo(),
})
export default isWindows
