import { browser } from './_class'

const SCROLL_SPEED = 200
const SCROLL_THRESHOLD = typeof window === 'undefined' ? 0 : browser() === 'safari' ? 1 : 20
let isEnableScrolling = false
let scrollTimeout = null
let touchStartY = 0
let touchMoveY = 0
let scrolling = false

/// ****************************************************
// Easing Method
/// ****************************************************
// Cubic-Bezierの値を計算する関数を生成する関数
// ref; https://web.analogstd.com/tips/posts/js/cubic-bezier.php
const cubicBezier = (_x1, _y1, _x2, _y2) => {
    // Refer: http://www.moshplant.com/direct-or/bezier/math.html
    const cx = 3 * _x1
    const bx = 3 * (_x2 - _x1) - cx
    const ax = 1 - cx - bx
    const cy = 3 * _y1
    const by = 3 * (_y2 - _y1) - cy
    const ay = 1 - cy - by
    // 媒介変数表示したX座標
    const bezierX = (_t) => {
        return _t * (cx + _t * (bx + _t * ax))
    }
    // X座標のt微分
    const bezierDX = (_t) => {
        return cx + _t * (2 * bx + 3 * ax * _t)
    }
    // ニュートン法で数値解析する
    const newtonRaphson = (_x) => {
        if (_x <= 0) {
            return 0
        }
        if (_x >= 1) {
            return 1
        }
        let prev
        let t = _x
        do {
            prev = t
            t -= (bezierX(t) - _x) / bezierDX(t)
        } while (Math.abs(t - prev) > 1e-4) // 1e-2 程度でも良い
        return t
    }
    return (_t) => {
        // X座標(時刻)に対応する媒介変数tの値を取得する
        const t = newtonRaphson(_t)
        // Y座標(Easing量)を計算する
        return t * (cy + t * (by + t * ay))
    }
}
const easeKotIguchi = cubicBezier(0, 1.1, 0.01, 0.96)

/// ****************************************************
// Scroll to Top
/// ****************************************************

const scrollTo = (_to, _start, _duration, _resolve) => {
    const change = _to - _start
    let currentTime = 0
    const increment = 20
    if (Math.abs(change) > 20) {
        isEnableScrolling = true
        const animateScroll = () => {
            currentTime += increment
            // const val = easingEaseOutCubic(currentTime, _start, change, _duration)
            const val = _start + change * easeKotIguchi(currentTime / _duration)
            document.documentElement.scrollTop = val
            document.body.scrollTop = val
            if (currentTime < _duration && isEnableScrolling)
                scrollTimeout = setTimeout(animateScroll, increment)
            else if (_resolve != null) _resolve()
        }
        animateScroll()
    } else if (_resolve != null) _resolve()
}

const cancelScroll = () => {
    isEnableScrolling = false
    if (scrollTimeout !== null) window.clearTimeout(scrollTimeout)
}

export const adminActionPC = (_event) => {
    if (isEnableScrolling) {
        if (_event.deltaY >= SCROLL_THRESHOLD || _event.deltaY <= -1 * SCROLL_THRESHOLD)
            isEnableScrolling = false
    }
}

export const adminActionStartSP = (_event) => {
    if (isEnableScrolling) touchStartY = _event.touches[0].pageY
}

export const adminActionMoveSP = (_event) => {
    if (isEnableScrolling) {
        touchMoveY = _event.changedTouches[0].pageY - touchStartY
        if (touchMoveY >= SCROLL_THRESHOLD || touchMoveY < -1 * SCROLL_THRESHOLD)
            isEnableScrolling = false
    }
}

export const onClickScroll = (_targetPos, _speed = SCROLL_SPEED) => {
    if (scrolling) cancelScroll()
    scrolling = true
    const targetPos = _targetPos
    const start = document.documentElement.scrollTop || document.body.scrollTop
    const promise = new Promise((resolve) => {
        scrollTo(targetPos, start, _speed, resolve)
    })
    promise.then(() => {
        scrolling = false
    })
}
