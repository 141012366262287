import React from 'react'
import { useRecoilState } from 'recoil'
import PropTypes from 'prop-types'
// import { TransitionGroup, Transition as ReactTransition } from 'react-transition-group'
import * as styles from './style.module.sass'

import { menuOpenState } from '../../../hooks/atoms/menuOpen'

import Logo from '../../ui/logo'
import Navigation from '../../ui/navigation'
import MenuButton from '../../ui/menuButton'

// const appearTimeout = 200
// const getTransitionStyles = {
//     entering: {
//         opacity: 0,
//     },
//     entered: {
//         opacity: 1,
//     },
// }

const HeaderLayout = ({ _pdfLink, _logoTextList }) => {
    const [isMenuOpen] = useRecoilState(menuOpenState)

    const getMenuButtonClass = () => {
        let baseClass = ` ${styles.header__menu_button}`
        if (typeof window === 'undefined') return baseClass
        if (isMenuOpen) baseClass += ` ${styles.header__menu_button_open}`
        return baseClass
    }

    return (
        // <TransitionGroup>
        //     <ReactTransition
        //         timeout={{
        //             appear: appearTimeout,
        //         }}
        //         appear
        //     >
        //         {(status) => (
        <header
            className={styles.header}
            // style={{
            //     ...getTransitionStyles[status],
            // }}
        >
            <div className={styles.header__rel_wrap}>
                <div className={styles.header__logo}>
                    <Logo _logoTextList={_logoTextList} />
                </div>
                <div className={styles.header__navigation}>
                    <Navigation _pdfLink={_pdfLink} />
                </div>
            </div>
            <div className={getMenuButtonClass()}>
                <MenuButton />
            </div>
        </header>
        // )}
        //     </ReactTransition>
        // </TransitionGroup>
    )
}

HeaderLayout.defaultProps = {
    _pdfLink: null,
    _logoTextList: [],
}

HeaderLayout.propTypes = {
    _pdfLink: PropTypes.string,
    _logoTextList: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
}

export default HeaderLayout
