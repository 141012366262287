import React, { useRef, useEffect, useState } from 'react'
import * as styles from './style.module.sass'

import Hover from '../../utils/hover'

import SortArrowIcon from '../../../images/sort-arrow.svg'
import SortArrowIconSp from '../../../images/sort-arrow_sp.svg'

import { isSmartphone } from '../../../scripts/_class'
import {
    adminActionStartSP,
    adminActionMoveSP,
    adminActionPC,
    onClickScroll,
} from '../../../scripts/_scrollTo'

const ToTopButton = () => {
    useEffect(() => {
        if (typeof window === 'undefined') return null
        if (isSmartphone()) {
            window.addEventListener('touchstart', adminActionStartSP)
            window.addEventListener('touchmove', adminActionMoveSP)
        } else {
            window.addEventListener('wheel', adminActionPC)
        }
        return () => {
            if (isSmartphone()) {
                window.removeEventListener('touchstart', adminActionStartSP)
                window.removeEventListener('touchmove', adminActionMoveSP)
            } else {
                window.removeEventListener('wheel', adminActionPC)
            }
        }
    }, [])

    /// ****************************************************
    // Toggle Button
    /// ****************************************************

    // const scrollPos = useRef(
    //     typeof window === 'undefined'
    //         ? null
    //         : document.body.scrollTop || document.documentElement.scrollTop
    // )
    const ticking = useRef(false)
    const [isVisible, setVisible] = useState(false)

    const toggleButton = (_newState) => {
        setVisible(() => _newState)
    }

    const adminButtonState = () => {
        const nowScrollPos = document.body.scrollTop || document.documentElement.scrollTop
        // if (
        //     (nowScrollPos < 10 && isVisible) ||
        //     (nowScrollPos >= 10 && scrollPos.current < nowScrollPos && isVisible)
        // )
        //     toggleButton(false)
        // else if (nowScrollPos >= 10 && scrollPos.current > nowScrollPos && !isVisible)
        //     toggleButton(true)
        if (nowScrollPos < 150 && isVisible) toggleButton(false)
        else if (nowScrollPos >= 150 && !isVisible) toggleButton(true)
        // scrollPos.current = nowScrollPos
    }

    const scrollFunc = () => {
        if (ticking.current) return
        requestAnimationFrame(() => {
            ticking.current = false
            adminButtonState()
        })
        ticking.current = true
    }

    useEffect(() => {
        if (typeof window === 'undefined') return null
        window.addEventListener('scroll', scrollFunc, { passive: true })
        return () => window.removeEventListener('scroll', scrollFunc)
    }, [isVisible])

    return (
        <Hover hoverClass={styles.to_top_button_hovered}>
            <button
                type="button"
                className={
                    isVisible
                        ? styles.to_top_button
                        : `${styles.to_top_button} ${styles.to_top_button_hide}`
                }
                onClick={() => onClickScroll(0)}
            >
                <span className={styles.to_top_button__container}>
                    <span className={styles.to_top_button__icon}>
                        <picture className={styles.to_top_button__icon_img}>
                            <source srcSet={SortArrowIconSp} media="(max-width: 900px)" />
                            <img src={SortArrowIcon} alt="sort arrow" />
                        </picture>
                    </span>
                    <span className={styles.to_top_button__text}>Top</span>
                </span>
            </button>
        </Hover>
    )
}

export default ToTopButton
