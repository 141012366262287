// custom CSS styles
import './src/styles/main.sass'

// import React from 'react'
import { GatsbyBrowser } from 'gatsby'

const transitionDelay = 200
let timeout = null

const getDeviceInfo = () => {
    const ua = window.navigator.userAgent.toLowerCase()
    if (ua.indexOf('windows nt') !== -1) document.documentElement.classList.add('is-win')
}

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element }) => {
    getDeviceInfo()
    return element
}

export const shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
    if (typeof window === 'undefined') return false
    window.history.scrollRestoration = 'manual' // browserバックの時にボタンを押して直ぐにスクロールが発生するのを防ぐ
    if (timeout != null) window.clearTimeout(timeout)
    const savedPosition = getSavedScrollPosition(location)
    timeout = window.setTimeout(() => {
        if (location.action === 'PUSH') {
            window.scrollTo(0, 0)
        } else {
            window.scrollTo(...(savedPosition || [0, 0]))
        }
    }, transitionDelay + 10) // 10プラスしないと、前のページ上でスクロールが発生してうまくいかない
    return false
}
