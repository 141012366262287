import React from 'react'
import PropTypes from 'prop-types'
import * as styles from './style.module.sass'

import FooterLayoutModel from '../../model/footerLayoutModel'

const FooterLayout = ({ _time }) => {
    return (
        <footer className={styles.footer}>
            <FooterLayoutModel _time={_time} />
        </footer>
    )
}

FooterLayout.propTypes = {
    _time: PropTypes.number.isRequired,
}

export default FooterLayout
