import React, { useRef, useState, useEffect } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import { RecoilRoot } from 'recoil'

import * as styles from './style.module.sass'

import HeaderLayout from '../headerLayout'
import FooterLayout from '../footerLayout'
import Transition from '../../utils/transition'
import Navigation from '../../ui/navigation'
import ToTopButton from '../../ui/toTopButton'
import NotFoundText from '../../ui/notFoundText'

import PrependsScripts from '../../../scripts/_prepends'

const STORAGE_NAME = 'kiBrowsingTime'

const DefaultLayout = ({ children, location }) => {
    const faviconList = ['00.ico', '01.ico', '02.ico', '03.ico', '04.ico', '05.ico']
    let nowFaviconId = 0
    const faviconFrameRate = 2
    PrependsScripts(useRef, useEffect) // scriptsディレクトリ格納のscript群

    const [time, setTime] = useState(() => {
        if (typeof window === 'undefined') return false
        const storage = localStorage.getItem(STORAGE_NAME)
        return storage !== null ? Number(localStorage.getItem(STORAGE_NAME)) : 0
    })

    const is404Root = () => {
        // eslint-disable-next-line prefer-regex-literals
        const reg = new RegExp('/(.*?)(?=/)')
        const mathArr = location.pathname.match(reg)
        if (mathArr === null && location.pathname !== '/') return true
        if (mathArr === null) return false
        if (mathArr[1] === '404') return true
        if (mathArr[1] === 'project') return false
        if (mathArr[1] === 'about') return false
        if (mathArr[1] === 'contact') return false
        if (mathArr[1] === 'media') return false
        return true
    }

    // timer管理
    useEffect(() => {
        const timer = setTimeout(() => {
            setTime((_time) => _time + 1)
            localStorage.setItem(STORAGE_NAME, (time + 1).toString())
            // localStorage.removeItem(STORAGE_NAME) 削除したい場合に指定
        }, 1000)
        return () => clearTimeout(timer)
    }, [time])

    // animated favicon管理
    useEffect(() => {
        let link = document.getElementById('favicon')
        const interval = window.setInterval(() => {
            if (!link) link = document.getElementById('favicon')
            else {
                nowFaviconId += 1
                if (nowFaviconId === faviconList.length) nowFaviconId = 0
                link.href = `/favicon/${faviconList[nowFaviconId]}`
            }
        }, 1000 / faviconFrameRate)
        return () => window.clearInterval(interval)
    }, [location])

    const { allWp: data } = useStaticQuery(graphql`
        query {
            allWp: allWp {
                edges {
                    node {
                        themeOptions {
                            common {
                                pdfLink
                                logoTextList {
                                    logoText
                                }
                            }
                        }
                    }
                }
            }
        }
    `)
    const { pdfLink } = data.edges[0].node.themeOptions.common
    const { logoTextList } = data.edges[0].node.themeOptions.common

    return (
        <RecoilRoot>
            <>
                <HeaderLayout _pdfLink={pdfLink} _logoTextList={logoTextList} />
                <main className={styles.main}>
                    <Transition location={location}>
                        <Navigation _pdfLink={pdfLink} _time={time} _isInMenu />
                        {!is404Root() ? children : <NotFoundText />}
                        <ToTopButton />
                        <FooterLayout _time={time} />
                    </Transition>
                </main>
            </>
        </RecoilRoot>
    )
}

DefaultLayout.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
}

export default DefaultLayout
